import request from '@/utils/request'

// 库存-订单创建信息预览
export function getPreview(params){
    return request({
        url:'/dsm/warehouse/in/batch/orders/preview',
        method:'get',
        params
    })
}

// 库存-订单创建-确认
export function create(data){
    return request({
        url:'/dsm/warehouse/in/batch/orders/create',
        method:'POST',
        data
    })
}