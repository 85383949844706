<template>
  <div class="add-product-container">
    <div class="container-title">
      <a-breadcrumb separator=">" class="breadcrumb">
        <a-breadcrumb-item>
          <a @click.prevent="$router.push('/index')">首页</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a
            @click.prevent="
              $router.push(
                `/buyercenter/stock?entityId=${$route.query.entityId}`
              )
            "
            >库存管理</a
          >
        </a-breadcrumb-item>
        <a-breadcrumb-item> 库存创建订单 </a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="container">
      <a-card class="add-content">
        <a-alert class="cascader-alert">
          <p slot="description">
            <b>您当前选择的商品类别是：</b>
            {{ info.categoryFirstValue + '>' + info.categorySecondValue }}
          </p>
        </a-alert>

        <a-card class="l-card">
          <div class="title">分类属性</div>
          <a-row>
            <a-col
              :span="24"
              v-for="(item, index) in selectClassify.property"
              :key="item.id"
            >
              <a-col :span="3" class="label-right">
                <span class="font-error" v-if="index === 0">*</span>
                {{ item.attrName }}：
              </a-col>
              <a-col :span="11">
                <a-select
                  show-search
                  :value="item.value"
                  @change="item.value = $event"
                  style="width: 240px"
                  :placeholder="'请选择' + item.attrName"
                >
                  <a-select-option v-for="name in item.attrValue" :key="name">
                    {{ name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col v-if="index === 0" :span="10" style="line-height: 32px">
                <a-switch
                  :checked="form.isSpec"
                  @click="form.isSpec = !form.isSpec"
                  style="margin-right: 16px"
                >
                  <a-icon slot="checkedChildren" type="check" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
                <span>发票上是否显示该规格</span>
              </a-col>
            </a-col>
          </a-row>
        </a-card>

        <a-card class="l-card">
          <div class="title">发票品类</div>
          <a-row>
            <a-col :span="3" class="label-right">
              <span class="font-error">*</span>发票类型：
            </a-col>
            <a-col :span="8">
              <a-select
                :value="taxRate.id"
                @change="typeTaxChange"
                placeholder="请选择发票类型"
                style="width: 100%"
                :dropdownMatchSelectWidth="false"
              >
                <a-select-option v-for="item in taxRateList" :key="item.id">
                  {{ item.policyName }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="3" class="label-right">
              <span class="font-error">*</span>品类名称：
            </a-col>
            <a-col :span="8">
              <a-select
                style="width: 100%"
                :value="invoiceInfo.index"
                @change="invoiceInfo.index = $event"
                placeholder="请选择品类名称"
              >
                <a-select-option
                  v-for="(item, index) in invoiceInfo.list"
                  :key="index"
                >
                  {{ item.taxClassName }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="3" class="label-right">品类编码：</a-col>
            <a-col :span="8" style="line-height: 32px">{{
              (invoiceInfo.list.length &&
                invoiceInfo.index !== undefined &&
                invoiceInfo.list[invoiceInfo.index].taxClassCode) ||
              ''
            }}</a-col>
            <a-col :span="3" class="label-right">规格型号：</a-col>
            <a-col :span="8" style="line-height: 32px">{{
              (form.isSpec &&
                selectClassify.property.length &&
                selectClassify.property[0].value) ||
              ''
            }}</a-col>
          </a-row>
        </a-card>

        <a-card class="l-card">
          <div class="title">地址信息</div>
          <a-row>
              <a-col :span="3" class="label-right">常用发货地：</a-col>
              <a-col :span="21">
                  <a-select style="width: 530px" placeholder="请选择" @change="selectAddress">
                      <a-select-option v-for="item in usualaddress" :key="item.addrFull">
                          {{`${item.addrFull}` }}
                      </a-select-option>
                  </a-select>
              </a-col>

              <a-col :span="3"  class="label-right">发货地：</a-col>
              <a-col :span="10">
                <a-space :size="32">
                  <a-cascader style="width: 320px" :value="cascaderValue" :field-names="{ value: 'key', label: 'label', children: 'children' }" :options="options" @change="cascaderChange" placeholder="请选择" />
                  <a-input style="width: 420px" v-model="form.addrDetail" placeholder="请输入详细地址"></a-input>
                </a-space>
              </a-col>
          </a-row>
        </a-card>

        <a-row type="flex" align="middle">
          <a-col :span="24">
            <a-space :size="24">
              <span class="title-left"> 下游管理 </span>
              <a-input
                style="width: 320px"
                :value="info.downstreamName"
                disabled
              ></a-input>
            </a-space>
          </a-col>

          <a-col :span="24" class="title-left">收货地址</a-col>
          <a-col :span="24" class="container-border">
            <a-col :span="14" style="border-right: 1px solid #ccc">
              <div class="col">
                <span class="label-text">地址标签：</span>{{ siteObj.tagAddr }}
              </div>
              <div class="col">
                <span class="label-text">收货地址：</span>{{ siteObj.fullAddr }}
              </div>
              <!-- <p class="rule site">库存创建订单，收货地址已固定，不可修改</p> -->
              <a class="rule" @click="setSite">修改地址</a>
            </a-col>
            <a-col :span="10" align="middle">
              <a-space :size="18" style="padding-left: 24px">
                <a-button
                  type="primary"
                  class="w140"
                  @click="
                    siteType = 1
                    showSite = true
                  "
                >
                  <a-icon type="environment" />切换地址
                </a-button>
                <a-button
                  type="primary"
                  class="w140"
                  @click="
                    siteType = 3
                    showSite = true
                  "
                >
                  <a-icon type="environment" />添加地址
                </a-button>
              </a-space>
            </a-col>
          </a-col>

          <a-col :span="24" class="title-left" v-if="info.supplierType !== '个人卖家'">发票信息</a-col>
          <a-col :span="24" class="container-border" v-if="info.supplierType !== '个人卖家'">
            <a-row style="width: 100%">
              <a-col :span="10" style="margin-bottom: 0">
                <div class="col">
                  <span class="label-text">发票抬头：</span
                  >{{ billInfo.supplierName }}
                </div>
                <div class="col">
                  <span class="label-text">开票地址：</span
                  >{{ billInfo.invoiceAddr }}
                </div>
                <div class="col">
                  <span class="label-text">开户行：</span
                  >{{ billInfo.invoiceBankName }}
                </div>
                <div class="col">
                  <span class="label-text">电子发票邮箱：</span
                  >{{ billInfo.invoiceEmail }}
                </div>
              </a-col>
              <a-col :span="10" style="margin-bottom: 0">
                <div class="col">
                  <span class="label-text">纳税人识别号：</span
                  >{{ billInfo.unifiedCode }}
                </div>
                <div class="col">
                  <span class="label-text">开票联系方式：</span
                  >{{ billInfo.invoiceMobile }}
                </div>
                <div class="col">
                  <span class="label-text">开户行账号：</span
                  >{{ billInfo.invoiceBankAcc }}
                </div>
              </a-col>
            </a-row>
            <a-button
              class="rule edit"
              size="large"
              type="primary"
              @click="$refs.stockBillInfo.visible = true"
              ><a-icon type="form" />修改信息</a-button
            >
          </a-col>

          <a-col :span="24" class="title-left">确认订单信息</a-col>
          <a-col :span="24">
            <a-table
              :columns="columns"
              :data-source="data"
              :pagination="false"
              :rowKey="
                (record, index) => {
                  return index
                }
              "
            >
              <template slot="commodity" slot-scope="text, record">
                <span v-if="record.custom">{{ text }}</span>
                <div v-else-if="record.customFooter">
                  <span style="float: left">备注：</span>
                  <div style="margin-left: 44px">
                    <a-textarea v-model="form.remark" :maxLength="200"></a-textarea>
                  </div>
                </div>
                <div v-else>
                  {{ tableName }}
                </div>
              </template>
              <template slot="ticket" slot-scope="text, record">
                <span v-if="record.customFooter">{{ text }}</span>
                <span v-else-if="!record.custom">{{ taxRate.policyName }}</span>
              </template>
              <template slot="totalPrice" slot-scope="text, record">
                <span v-if="!record.custom" class="matter fs14" :class="record.customFooter && 'fs18'"
                  >¥{{ text }}</span>
              </template>
            </a-table>
          </a-col>
        </a-row>
      </a-card>
      <a-card style="border-top: none">
        <div class="col">收货地址：{{ siteObj.fullAddr }}</div>
        <div class="col">
          <a-space :size="42">
            <span>发票抬头：{{ billInfo.supplierName }}</span>
            <span>纳税人识别号：{{ billInfo.unifiedCode }}</span>
          </a-space>
        </div>
        <span class="rule"
          ><b class="fs16">合计付款：</b
          ><span class="matter fs24">¥{{this.totalPrice.toFixed(2)}}</span></span
        >
      </a-card>
      <div class="create">
        <a-button type="danger" size="large" :loading="loading" @click="create">生成订单</a-button>
      </div>
    </div>
    <stockBillInfo
      ref="stockBillInfo"
      :obj="billInfo"
      @change="getsupplierinf"
    />
    <site
      v-if="showSite"
      :siteType="siteType"
      :show.sync="showSite"
      :defaultId="siteObj.id"
      @change="siteChange"
    />
  </div>
</template>

<script>
import { dsPur } from '@/api/buyerCenter'
import { getdefault } from '@/api/order'
import { getPreview, create } from '@/api/dsm'
import {
  querySecondAttr,
  queryCateroryName,
  queryRegionPolicy
} from '@/api/seller'
import { getsupplierinf, usualaddress } from '@/api/seller'
import stockBillInfo from './components/stockBillInfo.vue'
import site from './components/SiteCopy'
import options from '@/utils/ara'

export default {
  components: {
    stockBillInfo,
    site
  },
  data() {
    const columns = [
      {
        title: '供应商商品',
        dataIndex: 'commodity',
        scopedSlots: { customRender: 'commodity' },
        customCell: (row) => {
          const obj = {
            attrs: {}
          }
          if (row.customFooter) {
            obj.attrs.colSpan = 2
          }
          return obj
        }
      },
      {
        title: '发票名称',
        dataIndex: 'billName',
        scopedSlots: { customRender: 'billName' },
        customRender: (value, row) => {
          const obj = {
            children: (
              <div>
                {row.custom ? (
                  <span>{value}</span>
                ) : (
                  !row.customFooter && (
                    <div>
                      <p>{this.tableRaxName.taxName}</p>
                      <p>{this.tableRaxName.sizeName}</p>
                    </div>
                  )
                )}
              </div>
            ),
            attrs: {}
          }
          if (row.customFooter) {
            obj.attrs.colSpan = 0
          }
          return obj
        }
      },
      {
        title: '单价',
        dataIndex: 'unitPrice',
        align: 'right'
      },
      {
        title: '数量',
        dataIndex: 'netWeight',
        align: 'center'
      },
      {
        title: '票点',
        dataIndex: 'ticket',
        align: 'right',
        scopedSlots: { customRender: 'ticket' }
      },
      {
        title: '小计',
        dataIndex: 'totalPrice',
        align: 'right',
        scopedSlots: { customRender: 'totalPrice' }
      }
    ]
    return {
      columns,
      options,
      loading: false,
      info: {},
      data: [],
      dsList: [], // 下游管理列表
      downstreamId: null,
      taxRateList: [], // 发票类型
      // 税率
      taxRate: {
        id: '',
        policyName: '',
        vatRate: 0
      },
      // 一级二级三级分类控制
      selectClassify: {
        levelPrimary: '',
        levelBy: '',
        property: []
      },
      // 发票信息
      invoiceInfo: {
        list: [],
        index: undefined
      },
      siteObj: {
        id: undefined
      }, // 地址
      // 发票
      billInfo: {
        id: this.$route.query.entityId,
        supplierName: '',
        unifiedCode: '',
        invoiceBankName: '',
        invoiceBankAcc: '',
        invoiceMobile: '',
        invoiceEmail: '',
        invoiceAddr: ''
      },
      totalPrice: 0, // 合计付款
      showSite: false, // 地址
      siteType: 1, // 地址类型
      usualaddress: [], // 常用地址
      cascaderValue: [],
      form: {
        inIds: this.$route.query.inIds,
        addrDetail: '', // 地址信息
        unitsMeasure: 'T',
        isSpec: true, // 发票上是否显示该规格
        remark: '', // 备注
      }
    }
  },
  mounted() {
    dsPur(this.$route.query.entityId).then((res) => {
      if (res.code === 200) {
        this.dsList = res.data
      }
    })
    getPreview({ inIds: this.$route.query.inIds }).then((res) => {
      console.log(res)
      if (res.code !== 200) {
        this.$message.error(res.message)
        return
      }
      this.info = res.data
      this.getCheckOption(this.info.categorySecondId)
      queryRegionPolicy(this.info.supplierId).then((res) => {
        if (res.code !== 200) {
          this.$message.error(res.message)
        } else {
          if (!res.data || !res.data.length) {
            this.$message.error('卖家未绑定税费计算政策')
            this.taxDisabled = true
            return
          }
          this.taxRateList = res.data
          const { id, policyName, vatRate } = res.data[0]
          this.taxRate = { id, policyName, vatRate }
        }
      })
      usualaddress(this.info.supplierId).then(res => {
          if (res.code === 200) {
              this.usualaddress = res.data
          }
      })
      this.info.list.forEach((item) => (this.totalPrice += item.totalPrice))
      this.data = [
        {
          custom: true,
          commodity: `供应商：${this.info.supplierName}`,
          billName: `卖家：${this.info.supplierLegalName} / ${this.info.supplierMobile}`,
          netWeight: '单位：吨'
        },
        ...this.info.list,
        {
          customFooter: true,
          ticket: '店铺合计（含税费）',
          totalPrice: this.totalPrice.toFixed(2)
        }
      ]
    })
    this.getsupplierinf()
    this.getdefaults()
  },
  computed: {
    tableName() {
      let str = ''
      this.selectClassify.property.forEach(
        (item) => (str += ` ${item.value || ''}`)
      )
      return str
    },
    tableRaxName() {
      // return this.invoiceInfo.list.length && this.selectClassify.property.length && `发票品名：${this.invoiceInfo.list[this.invoiceInfo.index].taxClassName}
      // 规格：${this.selectClassify.property[0].value}`
      if (this.invoiceInfo.list.length && this.selectClassify.property.length) {
        return {
          taxName: `发票品名：${
            this.invoiceInfo.list[this.invoiceInfo.index].taxClassName
          }`,
          sizeName: `规格：${this.selectClassify.property[0].value}`
        }
      }
      return ''
    }
  },
  methods: {
    selectAddress(e) {
        const data = this.usualaddress.find(item => item.addrFull == e)
        this.cascaderValue = [data.provinceCode, data.cityCode, data.areaCode]
        const list = data.addrFull.split(' ')
        let address = ''
        list.forEach((item,index) => {
            if (index > 2) {
                address = address + item
            }
        })
        this.form.addrDetail = address
    },
    // 选择发货地
    cascaderChange(e) {
        this.cascaderValue = e
        this.form.addrDetail = ''
    },
    getCheckOption(id) {
      if (!id) {
        this.$message.error('二级分类为空')
        return
      }
      // 查找分类属性
      querySecondAttr(id).then((res) => {
        let data = res.data
        data.forEach((item, index) => {
          if (item.attrValue) {
            item.attrValue = item.attrValue.split(',')
          }
          item.value = index === 0 ? item.attrValue[0] : undefined
        })
        this.selectClassify.property = data
        console.log('分类属性', this.selectClassify.property)
      })
      // 查找发票信息
      queryCateroryName(id).then((res) => {
        console.log('发票信息', res)
        this.invoiceInfo.list = res.data
        // 查找默认值
        if (!res.data.length) {
          this.invoiceInfo.index = undefined
        } else {
          const index = this.invoiceInfo.list.findIndex(
            (item) => item.isDefault
          )
          this.invoiceInfo.index = index != -1 ? index : 0
        }
      })
    },
    typeTaxChange(e) {
      const { id, policyName, vatRate } = this.taxRateList.find(
        (item) => item.id === e
      )
      this.taxRate = { id, policyName, vatRate }
    },
    siteChange(e) {
      console.log(e)
      // if (e.id === this.siteObj.id) {
      //   getdefault({ purchaserId: this.$route.query.entityId }).then(res => {
      //     this.siteObj = res.data
      //   })
      //   return
      // }
      this.siteObj = e
    },
    setSite() {
      this.siteType = 2
      // if (!this.form.deliveryAddressId) {
      //   this.$message.error('请先切换地址')
      //   return
      // }
      this.showSite = true
    },
    // 获取默认地址
    getdefaults() {
      getdefault({
        purchaserId: this.$route.query.entityId
      }).then((res) => {
        if (res.code === 200) {
          this.siteObj = res.data
        }
      })
    },
    //  发票管理
    getsupplierinf() {
      getsupplierinf({
        entityCharacter: 'P',
        id: this.$route.query.entityId
      }).then((res) => {
        if (res.code === 200 && res.data) {
          this.billInfo = {
            id: this.$route.query.entityId,
            supplierName: '',
            unifiedCode: '',
            invoiceBankName: '',
            invoiceBankAcc: '',
            invoiceMobile: '',
            invoiceEmail: '',
            invoiceAddr: '',
            ...res.data
          }
        }
      })
    },
    create () {
      // 转换二级分类属性参数
      const attributes = {}
      this.selectClassify.property.forEach(item => {
          attributes[item.attrName] = item.value
      })
      const obj = {
        attributes: JSON.stringify(attributes),
        deliveryAddressId: this.siteObj.id,
        areaCode: this.cascaderValue[2], // 区code
        categorySecondTaxCodeId: this.invoiceInfo.list[this.invoiceInfo.index].id, // 税区类别id
        taxRegionPolicyId: this.taxRate.id, // 税区id
        specName: this.selectClassify.property.length && this.selectClassify.property[0].value || ' ', // 商品规格（可以勾选不显示规格，则用空格代替）
        ...this.form
      }
      if (!obj.areaCode) {
        this.$message.error('请补充地址信息')
        return
      }
      if (!obj.deliveryAddressId) {
        this.$message.error('请补充收货地址')
        return
      }
      console.log(obj);
      this.loading = true
      create(obj).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.$message.success(res.message)
          this.$router.push(`/succee-order?type=stock&purchaserId=${this.$route.query.entityId}&id=${res.data}`)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.add-product-container {
  margin: 0 auto;
  max-width: 1440px;
  min-width: 990px;
  font-size: 16px;
  .container-title {
    margin: auto;
    width: 1100px;
    @media screen and (max-width: 1440px) {
      width: 100%;
      max-width: 1440px;
      min-width: 990px;
      padding: 0 15px;
    }
  }
  .container {
    font-size: 14px;
    margin-bottom: 32px;
    // display: flex;
    // justify-content: center;
    margin: 0 auto;
    @media screen and (max-width: 1440px) {
      width: 100%;
      max-width: 1440px;
      min-width: 990px;
      padding: 0 15px;
    }
    @media screen and (min-width: 1440px) {
      width: 1100px;
    }
    .add-content {
      .cascader-alert {
        margin-bottom: 32px;
      }
      /deep/ .ant-card-head {
        background: #e9e9e9;
      }
      /deep/ .ant-col {
        margin-bottom: 12px;
      }
      .label-right {
        line-height: 32px;
        text-align: right;
      }
      /deep/ .ant-alert-with-description.ant-alert-no-icon {
        padding: 6px 16px;
      }
      /deep/ .ant-card {
        margin-bottom: 32px;
      }
      .l-card {
        position: relative;
        .title {
          content: '分类属性';
          position: absolute;
          top: -12px;
          left: 16px;
          background: #fff;
          z-index: 1;
          font-weight: bold;
          padding: 0 12px;
          color: #000;
        }
      }
    }
  }
  .container-border {
    border: 1px solid #e4e4e4;
    padding: 24px;
    position: relative;
    display: flex;
    align-items: center;
    .label {
      float: left;
    }
    .label-text {
      color: #999999;
    }
    .col-text {
      margin-left: 60px;
    }
    .site {
      color: #1abc9c;
    }
    .edit {
      width: 150px;
    }
    /deep/ .ant-col {
      margin-bottom: 0 !important;
    }
  }
  .matter {
    font-weight: 700;
    color: #ff0000;
  }
  .fs14 {
    font-size: 14px;
  }
  .fs18 {
    font-size: 18px;
  }
  .fs16 {
    font-size: 16px;
    vertical-align: text-bottom;
  }
  .fs24 {
    font-size: 24px;
  }
  .title-left {
    font-size: 16px;
    color: #666666;
    margin-top: 20px;
  }
  .rule {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
  .col {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .create {
    text-align: right;
    margin-bottom: 64px;
    button {
      width: 180px;
    }
  }
}
.font-error {
  color: @error;
}

.w140 {
  width: 150px;
  height: 40px;
  color: #999;
  background: rgb(245, 245, 245);
  border: none;
  &:hover {
    color: rgb(51, 51, 51);
  }
}
</style>