<template>
  <a-modal
    :width="600"
    :title="TITLE[type]"
    :visible="show"
    @cancel="$emit('update:show', false)"
    :footer="null"
    centered>
    <template v-if="type === 1">
      <div class="site">
        <div v-for="item in list" :key="item.id" class="site-item" :class="item.id === checkId && 'site-check'" @click="checkId = item.id">
          <a-row justify="space-between" type="flex" align="middle">
            <a-col>
              <p>地址标签 : {{ item.tagAddr }}</p>
              <p class="site-text">收货地址 : {{ item.fullAddr }}</p>
            </a-col>

            <a-col>
              <a-space>
                <a @click.stop="type = 2;editObj = {...editObj, ...item};checked = !!item.isDefault">修改地址</a>
                <span v-if="item.isDefault">默认地址</span>
                <a v-else style="color: #1890ff" @click.stop="setDefault(item.id)">设为默认</a>
              </a-space>
            </a-col>
          </a-row>
        </div>

        <a-row class="site-item" type="flex" align="middle" justify="center" @click.native="type = 3">
          <a-col align="center"><span class="add-text">+ 新增地址</span></a-col>
        </a-row>
      </div>

      <a-row type="flex" align="middle" justify="center">
        <a-space>
          <a-button size="large" v-if="!siteType" @click="$emit('update:show', false)">取消</a-button>
          <a-button size="large" :disabled="!checkId" type="danger" @click="$emit('change', list.find(item => item.id === checkId));$emit('update:show', false)">确定</a-button>
        </a-space>
      </a-row>
    </template>

    <template v-else-if="type === 2">
      <a-row class="edit">
        <a-col>
          <p>地址标签</p>
          <a-input v-model="editObj.tagAddr" placeholder="给该地址一个别名，便于识别。例如：青岛工厂仓库"></a-input>
        </a-col>

        <a-col>
          <p>省份/自治区</p>
          <a-cascader
            :value="[editObj.provinceCode,editObj.cityCode,editObj.areaCode]"
            style="width: 100%"
            :field-names="{ value: 'key', label: 'label', children: 'children' }"
            :options="site"
            @change="cascaderChange($event, 'editObj')"
            placeholder="选择省份/自治区" />
        </a-col>

        <a-col>
          <p>详细地址</p>
          <a-textarea
            v-model="editObj.detailAddr"
            placeholder="完善详细地址"
            :auto-size="{ minRows: 3, maxRows: 7 }"
          />
        </a-col>

        <a-col>
          <a-checkbox :checked="checked" @change="checked = $event.target.checked">
            默认地址
          </a-checkbox>
        </a-col>
      </a-row>

      <a-row type="flex" align="middle" justify="center">
        <a-space>
          <a-button size="large" v-if="!siteType" @click="type = 1;restart('editObj')">返回</a-button>
          <a-button size="large" type="danger" @click="update">确定</a-button>
        </a-space>
      </a-row>
    </template>

    <template v-else>
      <a-row class="edit">
        <a-col>
          <p>地址标签</p>
          <a-input v-model="createObj.tagAddr" placeholder="给该地址一个别名，便于识别。例如：青岛工厂仓库"></a-input>
        </a-col>

        <a-col>
          <p>省份/自治区</p>
          <a-cascader style="width: 100%" :field-names="{ value: 'key', label: 'label', children: 'children' }" :options="site" @change="cascaderChange($event, 'createObj')" placeholder="选择省份/自治区" />
        </a-col>

        <a-col>
          <p>详细地址</p>
          <a-textarea
            v-model="createObj.detailAddr"
            placeholder="完善详细地址"
            :auto-size="{ minRows: 3, maxRows: 7 }"
          />
        </a-col>

        <a-col>
          <a-checkbox :checked="checked" @change="checked = $event.target.checked">
            默认地址
          </a-checkbox>
        </a-col>
      </a-row>

      <a-row type="flex" align="middle" justify="center">
        <a-space>
          <a-button size="large" v-if="!siteType" @click="type = 1;restart('createObj')">返回</a-button>
          <a-button size="large" type="danger" @click="create">确定</a-button>
        </a-space>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import site from '@/utils/ara'
import { list, createAddress, updateAddress, setdefault } from '@/api/order'
const TITLE = {
    1: '选择收货地址',
    2: '修改收货地址',
    3: '添加收货地址'
}
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        purchaserIdId: {
          // eslint-disable-next-line vue/require-prop-type-constructor
          type: Number | String,
          default: null
        },
        defaultId: {
          type: Number,
          default: null
        },
        siteType: {
          type: Number,
          default: null
        }
    },
    data () {
        return {
            type: this.siteType || 1,
            TITLE,
            site,
            list: [],
            checkId: this.defaultId,
            checked: false,
            createObj: {
                purchaserId: this.$route.query.entityId || this.purchaserIdId,
                provinceCode: '',
                areaCode: '',
                cityCode: '',
                detailAddr: '',
                tagAddr: ''
            },
            editObj: {
                purchaserId: this.$route.query.entityId || this.purchaserIdId,
                provinceCode: '',
                areaCode: '',
                cityCode: '',
                detailAddr: '',
                tagAddr: ''
            }
        }
    },
    mounted () {
      if (this.siteType === 3) {
        return
      }
      this.getList()
    },
    methods: {
        cascaderChange (e, str) {
            this[str].provinceCode = e[0]
            this[str].cityCode = e[1]
            this[str].areaCode = e[2]
        },
        // 获取采购商地址
        getList () {
            list({purchaserId: this.$route.query.entityId || this.purchaserIdId}).then(res => {
              this.list = res.data
              if (this.siteType === 2) {
                const data = this.list.find(item => item.id === this.checkId) || {}
                this.editObj = { ...this.editObj, ...data }
                this.checked = !!this.editObj.isDefault
                console.log(this.editObj)
                return
              }
              this.type = 1
            })
        },
        // 创建收货地址
        create () {
            if (!this.error('createObj')) return
            console.log('????')
            createAddress({ ...this.createObj, isDefault: this.checked ? 1 : 0 }).then(res => {
                if (res.code === 200) {
                    this.$message.success(res.message)
                    this.$emit('update:show', false)
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 更新收货地址
        update () {
            if (!this.error('editObj')) return
            const config = { ...this.editObj, addrId: this.editObj.id, isDefault: this.checked ? 1 : 0 }
            console.log(config)
            updateAddress(config).then(res => {
                if (res.code === 200) {
                    this.$message.success(res.message)
                    if (this.checkId === this.editObj.id) {
                      this.$emit('change', { ...this.editObj, addrId: this.editObj.id })
                    }
                    this.$emit('update:show', false)
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 重置
        restart (str) {
            this[str] = {
                purchaserId: this.$route.query.entityId || this.purchaserIdId,
                provinceCode: '',
                areaCode: '',
                cityCode: '',
                detailAddr: '',
                tagAddr: ''
            }
        },
        // 错误
        error (str) {
            if (!this[str].tagAddr) {
                this.$message.error('请为收货地址设个标签')
            } else if (!this[str].cityCode || !this[str].areaCode || !this[str].provinceCode) {
                this.$message.error('请选择地市')
            } else if (!this[str].detailAddr) {
                this.$message.error('请填写详细地址')
            } else {
                return true
            }
        },
        setDefault (addrId) {
          const that = this
          this.$confirm({
            title: '提示',
            content: '请确认是否设置默认地址',
            onOk () {
              return new Promise((resolve, reject) => {
                setdefault({ addrId }).then(res => {
                  if (res.code === 200) {
                    that.$message.success(res.message)
                    that.getList()
                    resolve()
                  } else {
                    that.$message.error(res.message)
                    reject(new Error())
                  }
                })
              })
            }
          })
        }
    }
}
</script>

<style lang="less" scoped>
@primary: rgba(240, 72, 68, 1);
p {margin-bottom: 0;}
button {
    width: 150px;
}
a {
    color: @primary;
    &:hover {
        color: @primary;
    }
}
.site {
    height: 510px;
    margin-bottom: 14px;
    overflow-y: auto;
}
.site-item {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    padding: 24px;
    margin-bottom: 10px;
    height: 100px;
    cursor: pointer;
    line-height: 25px;
    transition: all .3s;
    /deep/ .ant-col {
        margin-bottom: 0;
    }
    .site-text {
        max-width: 360px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .add-text {
        font-size: 20px;
    }
  &:hover {
     border-color: red;
  }
}
.site-check {
    border-color: red;
}
.edit {
    margin-bottom: 14px;
    p {
        margin-bottom: 4px;
    }
    /deep/ .ant-col {
        margin-bottom: 14px;
    }
}
</style>
